import React from "react";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <Layout>
      <div>
        <div className="py-20">
          <h2 className="text-5xl  font-normal text-center  mb-8">
            We'd Love to Hear From You
          </h2>
          <ContactForm />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
