import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import Oral from "../img/women.jpg";
import Morning from "../img/w2.jpg";
import Unsure from "../img/preg.jpg";
import { motion } from "framer-motion";
import StarRating from "./StaRating";
import axios from "axios";
import defaultUserImage from "../img/5907.jpg";

interface RatingAndReview {
  displayName: string;
  imgUrl: string;
  rating: number;
  review: string;
  role: string;
}

const testimonialsPerPage = 3;

const testimonialVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.5 },
  }),
};

const Testimonials: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reviews, setReviews] = useState<RatingAndReview[]>([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/getallReviews`)
      .then((response) => {
        if (response.data.success) {
          const fetchedReviews: RatingAndReview[] = response.data.reviews.map(
            (review: any) => ({
              displayName: review.userDetails?.displayName || "Anonymous",
              imgUrl: review.userDetails?.imgUrl || "",
              rating: review.rating,
              review: review.review,
              role: "Client",
            })
          );

          setReviews(fetchedReviews);
        }
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, [baseUrl]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? reviews.length - testimonialsPerPage : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 >= reviews.length ? 0 : prevIndex + 1
    );
  };

  const visibleTestimonials = [
    ...reviews.slice(currentIndex, currentIndex + testimonialsPerPage),
    ...reviews.slice(
      (currentIndex + testimonialsPerPage) % reviews.length,
      ((currentIndex + testimonialsPerPage) % reviews.length) +
        testimonialsPerPage
    ),
  ].slice(0, testimonialsPerPage);

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-m uppercase font-bold text-center mb-8">
          Don’t take our word, Take theirs
          <span className="block mt-2 h-1 w-20 bg-primary-lighter mx-auto"></span>
        </h2>
        <div className="flex items-center justify-between">
          <FaArrowLeft
            size={30}
            className="text-2xl text-primary-lightd cursor-pointer"
            onClick={handlePrev}
          />
          <div className="px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {visibleTestimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                className="bg-primary-light p-6 rounded-lg shadow-lg"
                custom={index}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
                variants={testimonialVariants}
              >
                <div className="flex items-center mb-4">
                  <img
                    src={
                      testimonial.imgUrl ? testimonial.imgUrl : defaultUserImage
                    }
                    alt={testimonial.displayName}
                    className="w-24 h-24 rounded-full object-cover mr-4"
                  />
                  <div>
                    <h3 className="text-xl text-gray-100 font-semibold">
                      {testimonial.displayName}
                    </h3>
                    <p className="text-white">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-white mb-4">"{testimonial.review}"</p>
                <div className="flex bottom-0">
                  <StarRating rating={testimonial.rating} />
                </div>
              </motion.div>
            ))}
          </div>
          <FaArrowRight
            size={30}
            className="text-2xl text-primary-lightd cursor-pointer"
            onClick={handleNext}
          />
        </div>
        <div className="flex justify-center mt-8">
          {[...Array(Math.ceil(reviews.length / testimonialsPerPage))].map(
            (_, i) => (
              <div
                key={i}
                className={`w-3 h-3 rounded-full mx-1 ${
                  i === Math.floor(currentIndex / testimonialsPerPage)
                    ? "bg-primary-lightest"
                    : "bg-gray-300"
                }`}
              ></div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
