import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { MdModeEdit } from "react-icons/md";
import ReviewAndRatingModal from "./ReviewAndRatingModal";
import toast, { Toaster } from "react-hot-toast";
import { LifeLine } from "react-loading-indicators";
import StarRating from "./StaRating";

interface BookingDetails {
  date: string;
  servicesNames: string[];
  transportation: string;
  doctor: string;
  time: string;
  transportationFee: number;
  totalServicePrice: number;
  userUID: string;
  bookingNumber: string;
  review: string;
  rating: number;
}

type MyBookingsProps = {
  userUID: string | undefined;
};

const MyBookings: React.FC<MyBookingsProps> = ({ userUID }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [bookingDetails, setBookingDetails] = useState<BookingDetails[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [noBookingHis, setNoBookingHis] = useState<boolean>(false);

  const [isRevAndRatModalOpen, setIsRevAndRatModalOpen] =
    useState<boolean>(false);
  const [review, setReview] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [revBookingNumber, setRevBookingNumber] = useState<string>("");
  const [savingReview, setSavingReview] = useState<boolean>(false);

  const closeReviewAndRate = () => {
    setIsRevAndRatModalOpen(false);
  };

  const handleSaveReviewAndRating = (newReview: string, newRating: number) => {
    setSavingReview(true);
    setReview(newReview);
    setRating(newRating);
    axios
      .post(`${baseUrl}/savereviewAndRating`, {
        userUID: userUID,
        bookingNumber: revBookingNumber,
        review: newReview,
        rating: newRating,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("Review and Rating saved!", { position: "top-center" });
          setIsRevAndRatModalOpen(false);
          setSavingReview(false);
          window.location.reload();
        } else {
          toast.error("Error saving mReview", {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.error("Error saving Review:", error);
        if (error.response) {
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error setting Review:", error.message);
        }
      });
  };

  const handleOpenReviewModal = (bookingNumber: string) => {
    setIsRevAndRatModalOpen(true);
    setRevBookingNumber(bookingNumber);
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/getuserbookings`, {
        headers: {
          userUID: userUID,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setBookingDetails(response.data.bookings);
          setLoadingTable(false);
        } else if (
          !response.data.success &&
          response.data.message === "notfound"
        ) {
          setLoadingTable(false);
          setNoBookingHis(true);
        } else {
          setLoadingTable(false);
        }
      })
      .catch((error) => {});
  }, [userUID, baseUrl]);

  if (loadingTable) {
    return (
      <div className="flex items-center justify-center ">
        <LifeLine
          color="#ff5d8f"
          size="medium"
          text="Loading Booking History .."
          textColor=""
        />
      </div>
    );
  }

  if (noBookingHis) {
    return (
      <div className=" items-center justify-center ">
        <h1 className="py-2 text-primary-lightest font-semibold text-center">
          My Bookings
        </h1>
        <p className="text-sm md:text-l text-center">
          You do not have any Bookings Yet All your bookings will appear here.
        </p>
      </div>
    );
  }

  return (
    <div className="px-20 overflow-x-auto ">
      <ReviewAndRatingModal
        isOpen={isRevAndRatModalOpen}
        onClose={closeReviewAndRate}
        saveReviewAndRating={handleSaveReviewAndRating}
        rating={rating}
        review={review}
        savingReview={savingReview}
      />

      <Toaster position="top-center" reverseOrder={false} />

      <h1 className="py-2 text-primary-lightest font-semibold">My Bookings</h1>
      <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
        <thead className="text-xs uppercase dark:bg-primary-light">
          <tr>
            <th scope="col" className="px-6 py-3 text-center">
              Service
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Booking Number
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Doctor
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Total Price
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Date
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Time
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Review
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Rating
            </th>
          </tr>
        </thead>
        <tbody>
          {bookingDetails.map((booking, index) => (
            <tr key={index} className="border-b border-primary-light">
              <th
                scope="row"
                className="px-6 font-medium text-gray-500 whitespace-nowrap text-center"
              >
                {booking.servicesNames.join(", ")}
              </th>
              <td className="px-6 py-4 text-center">{booking.bookingNumber}</td>
              <td className="px-6 py-4 text-center">{booking.doctor}</td>
              <td className="px-6 py-4 text-center">
                R{booking.totalServicePrice}
              </td>
              <td className="px-6 py-4 text-center">{booking.date}</td>
              <td className="px-6 py-4 text-center">{booking.time}</td>
              <td className="px-6 py-4 text-center text-l relative group">
                {booking.review && booking.review.length > 50 ? (
                  <>
                    {`${booking.review.slice(0, 50)} ...`}
                    <span className="tooltip absolute left-0 top-0 mt-2 w-auto p-2 text-xs text-gray-400 bg-primary-whitish rounded hidden group-hover:block">
                      {booking.review}
                    </span>
                  </>
                ) : (
                  booking.review || (
                    <p className="flex items-center justify-center">
                      No Review{" "}
                      <MdModeEdit
                        className="ml-2 cursor-pointer"
                        onClick={() =>
                          handleOpenReviewModal(booking.bookingNumber)
                        }
                      />{" "}
                    </p>
                  )
                )}
              </td>

              <td className="px-6 py-4 flex items-center justify-center text-center">
                {/* Assuming there's a `rating` property */}
                <StarRating rating={booking.rating} />
                {!booking.rating && (
                  <MdModeEdit
                    className="ml-2 cursor-pointer"
                    onClick={() => handleOpenReviewModal(booking.bookingNumber)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MyBookings;
