// src/features/counter/counterSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  count1: number;
  count2: number;
  count3: number;
}

const initialState: CounterState = {
  count1: 1,
  count2: 1,
  count3: 1,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment1: (state) => {
      state.count1 += 1;
    },
    decrement1: (state) => {
      state.count1 = Math.max(state.count1 - 1, 1);
    },
    increment2: (state) => {
      state.count2 += 1;
    },
    decrement2: (state) => {
      state.count2 = Math.max(state.count2 - 1, 1);
    },
    increment3: (state) => {
      state.count3 += 1;
    },
    decrement3: (state) => {
      state.count3 = Math.max(state.count3 - 1, 1);
    },
    setCount: (
      state,
      action: PayloadAction<{ count1: number; count2: number; count3: number }>
    ) => {
      state.count1 = action.payload.count1;
      state.count2 = action.payload.count2;
      state.count3 = action.payload.count3;
    },
  },
});

export const {
  increment1,
  decrement1,
  increment2,
  decrement2,
  increment3,
  decrement3,
} = counterSlice.actions;

export default counterSlice.reducer;
