import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBs4t3MZawzAb65OryjT2D_nvkM-PFrXSs",
  authDomain: "femme-menziwebsite.firebaseapp.com",
  projectId: "femme-menziwebsite",
  storageBucket: "femme-menziwebsite.appspot.com",
  messagingSenderId: "745129228825",
  appId: "1:745129228825:web:df3af882cdea29aa6c4888",
  measurementId: "G-47QBZMPVJZ",
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
export const auth = getAuth(app);
export const firestore = getFirestore(app);

// Set up Google Auth Provider
export const googleProvider = new GoogleAuthProvider();
