import React, { useState } from "react";

interface MedicalHistoryProps {
  isOpen: boolean;
  onClose: () => void;
  saveMedicalHistory: () => void;
}

const SaveMedicalHistory: React.FC<MedicalHistoryProps> = ({
  isOpen,
  onClose,
  saveMedicalHistory,
}) => {
  if (!isOpen) return null;

  return (
    <div className="px-3 md:px-0  fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-black">
          Save Medical Information
        </h2>
        <p className="mb-4 text-black">
          Do you want to save your medical history? <br /> This will make the
          booking process seamless when you book again
        </p>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2  bg-primary-lighter rounded hover:bg-primary-lightd"
          >
            No
          </button>
          <button
            onClick={saveMedicalHistory}
            className="px-4 py-2 bg-primary-lighter text-white rounded hover:bg-primary-lightd"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveMedicalHistory;
