import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { increment1, decrement1 } from "../counterSlice";

const Increment1: React.FC = () => {
  const count1 = useSelector((state: RootState) => state.counter.count1);
  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-center rounded-md bg-white p-2">
      <button
        onClick={() => dispatch(decrement1())}
        className="text-xl px-3 py-1 border rounded-l-md bg-gray-200 hover:bg-gray-300"
      >
        -
      </button>
      <span className="text-xl mx-4">{count1}</span>
      <button
        onClick={() => dispatch(increment1())}
        className="text-xl px-3 py-1 border rounded-r-md bg-gray-200 hover:bg-gray-300"
      >
        +
      </button>
    </div>
  );
};

export default Increment1;
