import React from "react";
import Layout from "../components/Layout";
import hereoImage from "../img/w3.jpg";
import { motion, useInView } from "framer-motion";

const About = () => {
  return (
    <Layout>
      <div className="bg-white text-gray-800">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center h-[400px] flex items-center justify-center"
          style={{ backgroundImage: `url(${hereoImage})` }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <motion.h1
            className="relative text-center text-4xl md:text-5xl text-white font-bold"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            About FEMME
          </motion.h1>
        </section>

        {/* Combined Introduction and Mission Section */}
        <section className="py-12 px-6 md:px-12 bg-gray-50">
          <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 items-center">
            {/* Introduction Section */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.3 }}
            >
              <h2 className="text-3xl font-semibold mb-4 text-primary-lightest">
                Meet FEMME
              </h2>
              <p className="text-l text-gray-600 ">
                The new after hour female health and family planning service by
                Dr Refiloe Nkadimeng. FEMME offers women appointments,
                consultations and multiple contraceptive options after hours!
                Save yourself the pharmacy trips, the encounters, the
                conversation. Choose convenience. FEMME is there when everything
                else isn’t. For women, by women.
              </p>
            </motion.div>

            {/* Mission Section */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.3 }}
              className="flex  md:flex-row items-center gap-8"
            >
              <div>
                <h2 className="text-3xl font-semibold mb-4 text-primary-lightest">
                  Our Mission
                </h2>
                <p className="text-l text-gray-600">
                  Our mission is to empower women through informed health
                  choices, offering comprehensive services in contraception,
                  reproductive health, and overall wellness. We believe in
                  providing a supportive environment where patients feel heard
                  and respected.
                </p>
              </div>
            </motion.div>
          </div>
        </section>
        <motion.section
          className="py-12  px-6 md:px-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.3 }}
        >
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-semibold mb-4">Get in Touch</h2>
            <p className="text-l text-gray-600 mb-6">
              Ready to schedule your appointment? Visit our booking page for a
              seamless and efficient process
            </p>
            <a
              href="/booking"
              className="inline-block bg-primary-lightest hover:bg-pink-600 text-white font-semibold py-3 px-8 rounded-lg transition duration-200"
            >
              Book Now
            </a>
          </div>
        </motion.section>
        {/* Services Section */}
        {/* Your existing Services Section code here */}

        {/* Testimonials Section */}
        <motion.section
          className="py-12 bg-gray-50 px-6 md:px-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.3 }}
        >
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl text-gray-400 font-semibold mb-6">
              Patient Testimonials
            </h2>
            <p className="text-lg italic text-primary-light">
              "Dr. Nkadimeng's personalized care and compassionate approach have
              made a significant difference in my health journey. I always feel
              comfortable and understood during my visits."
            </p>
            <p className="mt-4 text-gray-500">- Satisfied Patient</p>
          </div>
        </motion.section>

        {/* Contact Section */}
      </div>
    </Layout>
  );
};

export default About;
