import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AppointmentDetails from "./types/appointmentDetails";

interface CartItem {
  item: string;
  price: number;
  quantity: number;
}

interface CartState {
  items: CartItem[];
  bookingInfo: AppointmentDetails;
}

const initialState: CartState = {
  items: [],
  bookingInfo: {
    date: "",
    bookingNumber: "",
    selectedServices: [],
    selectedServicesPrices: [],
    selectedDoctor: "",
    selectedTimeSlot: "",
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    medicalHistory: "",
    allergies: [],
    userUID: "",
    clientAddress: "",
    transportOption: "",
    servicePrice: 0,
    transportationFee: 0,
    totalToPay: 0,
  },
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (
      state,
      action: PayloadAction<{
        items: CartItem[];
        bookingInfo: AppointmentDetails;
      }>
    ) => {
      // Update cart items
      state.items.push(...action.payload.items);

      // Update booking information
      state.bookingInfo = { ...action.payload.bookingInfo };
    },
    removeFromCart: (state, action: PayloadAction<number>) => {
      state.items.splice(action.payload, 1);
    },
    updateQuantity: (
      state,
      action: PayloadAction<{ index: number; quantity: number }>
    ) => {
      const { index, quantity } = action.payload;
      state.items[index].quantity = quantity;
    },

    clearCart: (state) => {
      state.items = [];
      state.bookingInfo = initialState.bookingInfo;
    },
  },
});

export const { addItemToCart, removeFromCart, updateQuantity, clearCart } =
  cartSlice.actions;
export default cartSlice.reducer;
