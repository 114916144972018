import React from "react";
import { MdEventAvailable } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaHeartbeat } from "react-icons/fa";
import { motion } from "framer-motion";

const serviceVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.5, duration: 0.5 },
  }),
};

const HeroServices: React.FC = () => {
  const services = [
    {
      icon: <MdEventAvailable size={42} />,
      title: "Convenience & Comfort",
      description:
        "Experience healthcare that prioritizes your comfort and fits seamlessly into your life.",
    },
    {
      icon: <HiOutlineLightBulb size={42} />,
      title: "Innovation & Efficiency",
      description:
        "Embrace cutting-edge solutions that make your health journey more efficient and effective.",
    },
    {
      icon: <FaHeartbeat size={42} />,
      title: "Female-Focused Care",
      description:
        "Our services are designed by women, for women, ensuring you receive the care you deserve.",
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-around gap-5  md:py-5 mt-10 md:mt-20 text-white">
      {services.map((service, index) => (
        <motion.div
          key={index}
          className="flex items-center p-2 md:p-4 border rounded-lg cursor-pointer"
          custom={index}
          initial="hidden"
          animate="visible"
          variants={serviceVariants}
          whileHover={{
            scale: 1.05,
            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="flex-shrink-0 mr-4 h-full">
            <div className="flex items-center justify-center h-full">
              {service.icon}
            </div>
          </div>
          <div>
            {/* Adjust text size for title and description */}
            <h3 className="text-sm md:text-xl text-primary-lightest font-semibold text-left">
              {service.title}
            </h3>
            <p className="text-xs md:text-sm text-left">
              {service.description}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default HeroServices;
