import React from "react";
import injection from "../img/w3.jpg";
import Oral from "../img/oral.jpg";
import Morning from "../img/w2.jpg";
import Unsure from "../img/preg.jpg";
import Consultation from "../img/w1.jpg";
import trans from "../img/womendrive.jpg";
import { motion } from "framer-motion";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import StarRating from "./StaRating";

// Define the type for each service
interface Service {
  title: string;
  description: string;
  price: string;
  image: string; // Path to the image/icon
}

// Services data
const services: Service[] = [
  {
    title: "Injection",
    description:
      "The contraceptive injection is a highly effective, long-acting form of birth control that provides protection for up to three months per dose. It works by releasing a hormone called progestin, which prevents ovulation, thickens cervical mucus to block sperm, and thins the uterine lining to prevent implantation. This method is ideal for those who prefer a low-maintenance option, as it requires only four injections per year. It’s important to note that it can take some time for fertility to return to normal after stopping the injections, so it may not be suitable for those planning a pregnancy soon.",
    price: "R400",
    image: injection,
  },
  {
    title: "Oral",
    description:
      "Oral contraceptives, commonly known as 'the pill,' are a daily medication that helps prevent pregnancy by regulating hormones. These pills contain either a combination of estrogen and progestin or just progestin, which work together to prevent ovulation, thicken cervical mucus, and thin the uterine lining. The pill is taken at the same time each day, making it a convenient and reliable option for many. Beyond birth control, oral contraceptives can also help manage menstrual symptoms, reduce acne, and lower the risk of certain cancers. However, they require strict adherence to a daily routine for maximum effectiveness.",
    price: "R400",
    image: Oral,
  },
  {
    title: "Morning after pill",
    description:
      "The morning after pill is an emergency contraceptive used to prevent pregnancy after unprotected intercourse or contraceptive failure, such as a broken condom. It’s most effective when taken as soon as possible, ideally within 24 hours, but it can still reduce the risk of pregnancy if taken within 72 hours. The pill works by delaying ovulation, preventing fertilization, or stopping a fertilized egg from implanting in the uterus. It’s important to understand that the morning after pill is not an abortion pill and will not affect an existing pregnancy. This option provides a crucial safety net but should not be used as a regular form of contraception.",
    price: "R250",
    image: Morning,
  },
  {
    title: "Vitamin Drip",
    description:
      "The Vitamin Drip is a specialized intravenous therapy designed to deliver a potent blend of vitamins, minerals, and antioxidants directly into the bloodstream, promoting enhanced absorption and immediate health benefits. This therapy is particularly beneficial for women, helping to boost energy levels, support the immune system, and improve skin health by providing essential nutrients that combat fatigue, stress, and signs of aging. It also aids in balancing hormones, which can be crucial during different life stages, such as pregnancy, postpartum recovery, or menopause.",
    price: "R1500",
    image: Consultation,
  },
  {
    title: "Consultation",
    description:
      "Our contraception consultation service offers personalized advice and guidance to help you navigate your options and choose the best method for your needs. Whether you're new to contraception or considering a change, our healthcare providers will take the time to understand your health history, lifestyle, and future plans. We’ll cover all available methods, from pills and patches to long-acting options like IUDs and implants. We also address any concerns or questions you may have, ensuring you feel confident and informed in your decision. This service is an essential step toward taking control of your reproductive health.",
    price: "R400",
    image: Unsure,
  },
  {
    title: "Transportation",
    description:
      "We provide transportation if you require a ride for your scheduled service.",
    price: "R100",
    image: trans,
  },
];

const serviceVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.5 },
  }),
};

const ServiceCard: React.FC<Service & { index: number }> = ({
  title,
  description,
  price,
  image,
  index,
}) => {
  return (
    <motion.div
      className="flex flex-col p-4 bg-white"
      custom={index}
      initial="hidden"
      whileInView="visible"
      variants={serviceVariants}
      viewport={{ once: true, amount: 0.3 }}
    >
      <div className="flex flex-wrap md:flex-nowrap mb-4 md:mb-0">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="w-full md:w-1/2 md:pl-4 flex flex-col justify-between">
          <div className="flex-grow">
            <h3 className="text-xl font-semibold text-gray-500">{title}</h3>
            <p className="text-gray-600 mt-2 text-xs md:text-base">
              {description}
            </p>
          </div>
          <div className="py-3 flex items-center justify-between w-auto">
            <span className="text-primary-lightest font-bold">{price}</span>
            <StarRating rating={5} />
          </div>
          <div className="mt-4">
            <Link to={"/booking"}>
              <button className="flex items-center bg-primary-lightest  text-white px-4 py-2 rounded-md hover:bg-primary-lightd transition duration-300">
                Book Now
                <IoIosArrowForward className="ml-4" fill="#ffffff" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Services: React.FC = () => {
  return (
    <div className="py-10 px-10">
      <h2 className="text-m uppercase font-bold text-center mb-8">
        Our Services
        <span className="block mt-2 h-1 w-10 bg-primary-lighter mx-auto"></span>
      </h2>
      <div className="grid gap-6 grid-cols-1 mdt:grid-cols-2">
        {services.map((service, index) => (
          <ServiceCard
            key={service.title}
            index={index}
            title={service.title}
            description={service.description}
            price={service.price}
            image={service.image}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
