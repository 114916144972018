import React, { useState } from "react";
import { FaCartPlus, FaBox } from "react-icons/fa";

interface CartButtonProps {
  onClick: () => void;
}

const CartButton: React.FC<CartButtonProps> = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);
  const [showText, setShowText] = useState(true);

  const handleClick = () => {
    if (!clicked) {
      setClicked(true);
      setShowText(false); // Hide the text when the animation starts

      setTimeout(() => {
        setShowText(true); // Show the "Added" text after the animation completes
      }, 700);
      onClick();
      // Match this timeout with the animation duration
    }
  };

  return (
    <button
      className={`relative p-2 w-full h-16 border-0 rounded-lg bg-primary-lightest outline-none cursor-pointer text-white transition ease-in-out overflow-hidden ${
        clicked ? "scale-90" : ""
      }`}
      onClick={handleClick}
    >
      {showText && (
        <span
          className={`absolute z-30 top-1/2 text-l transform -translate-x-1/2 -translate-y-1/2 transition-opacity ${
            clicked ? "opacity-100" : "opacity-100"
          }`}
        >
          {clicked ? "Service(s) Added " : "Add Service(s) to cart"}
        </span>
      )}
      <FaCartPlus
        size={23}
        className={`absolute z-20 top-1/2 left-1 text-2xl transform -translate-y-1/2 transition-all duration-700 ${
          clicked ? "translate-x-full" : "translate-x-0"
        }`}
      />

      <FaBox
        size={9}
        className={`absolute z-20 top-0 left-7 text-xl transform transition-all duration-700 ${
          clicked
            ? "translate-x-full translate-y-6"
            : "translate-x-0 -translate-y-12"
        }`}
      />
    </button>
  );
};

export default CartButton;
