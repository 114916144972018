import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";

const steps = [
  {
    number: "01",
    title: "Select Expert Doctor",
    description:
      "Choose from a range of highly qualified and experienced doctors.",
    color: "bg-purple-500",
  },
  {
    number: "02",
    title: "Make Appointment",
    description: "Easily book an appointment at a convenient time for you.",
    color: "bg-teal-500",
  },
  {
    number: "03",
    title: "Get Consultation",
    description: "Receive professional medical advice and consultation.",
    color: "bg-orange-500",
  },
  {
    number: "04",
    title: "Get Care & Relief",
    description: "Experience compassionate care and effective treatment.",
    color: "bg-blue-500",
  },
];

const stepVariants = {
  hidden: (i: number) => ({
    opacity: 0,
    y: i % 2 === 0 ? -30 : 30,
  }),
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.5 },
  }),
};

interface HowWeWorkProps {
  heading: string;
  type: string;
}

const HowWeWork: React.FC<HowWeWorkProps> = ({ heading, type }) => {
  return (
    <div className="bg-gray-100 py-12">
      {type === "book" && (
        <h2 className="text-m uppercase font-bold text-center mb-8">
          {heading}
          <span className="block mt-2 h-1 w-20 bg-primary-lighter mx-auto"></span>
        </h2>
      )}
      <div
        className={`max-w-7xl ${type === "book" ? "py-10" : ""} mx-auto px-4`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center"
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.3 }}
              variants={stepVariants}
            >
              <div
                className={`w-16 h-16 rounded-full flex items-center justify-center ${step.color} text-white text-2xl font-bold`}
              >
                {step.number}
              </div>
              <h3 className="mt-4 text-lg font-semibold">{step.title}</h3>
              <p className="mt-2 text-sm text-gray-600">{step.description}</p>
              {/* {index < steps.length - 1 && (
                <FaArrowRight className="mt-4 text-gray-400 text-2xl" />
              )} */}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
