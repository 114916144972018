import React from "react";
import Layout from "../components/Layout";
import HeroSection from "../components/HeroSection";
import Services from "../components/Services";
import HowWeWork from "../components/HowWeWork";
import Testimonials from "../components/Testimonials";
import MeetOurSpecialists from "../components/MeetOurSpecialists";
import ContactForm from "../components/ContactForm";
import { motion } from "framer-motion";

const contactFormVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Home: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <Layout>
      <HeroSection />
      <Services />
      <HowWeWork type="book" heading="How we work" />
      <Testimonials />
      <MeetOurSpecialists />
      <motion.div
        className="py-12"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={contactFormVariants}
      >
        <ContactForm />
      </motion.div>
    </Layout>
  );
};

export default Home;
