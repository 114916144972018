import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { motion } from "framer-motion";
import { FaInstagram } from "react-icons/fa"; // Import Instagram icon

// Retrieve the Google Maps API key from the environment variables
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS as string;
const address = "868 Kyoto Street Kyalami Estates, Midrand";

const ContactForm = () => {
  return (
    <div className="py-10 px-4 md:px-10">
      <motion.h2
        className="text-lg uppercase font-bold text-center text-[#22223b] mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Contact Us
        <span className="block mt-2 h-1 w-10 bg-primary-lightest mx-auto"></span>
      </motion.h2>

      <div className="flex flex-col lg:flex-row items-center justify-center mb-8">
        <motion.div
          className="w-full lg:w-1/2 flex flex-col items-center lg:items-start mb-6 lg:mb-0 lg:ml-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="mb-4 text-center lg:text-left">
            <p className="text-lg lg:text-xl">
              If you wish to reach out to us, <br /> please fill in your
              details. Our team will get back to you soon.
            </p>
          </div>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex items-center mb-2">
              <FaPhone className="text-primary mr-2" fill="gray" />
              <a
                href="tel:+27714343982"
                className="text-primary-lightest hover:underline"
              >
                +27 71 434 3982
              </a>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-primary mr-2" fill="gray" />
              <a
                href="mailto:Femmefamilyplanning@gmail.com"
                className="text-primary-lightest hover:underline"
              >
                Femmefamilyplanning@gmail.com
              </a>
            </div>

            <div className="flex items-center mt-5">
              <a
                href="https://www.instagram.com/femme_familyplanning/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-lightest hover:text-primary flex items-center"
              >
                Visit us on:
                <FaInstagram className="ml-1" />
              </a>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="w-full lg:w-1/2 flex flex-col items-center lg:items-start p-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <input
            className="appearance-none border rounded-lg w-full md:w-3/4 py-3 px-3 mb-4 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Your Name"
          />
          <input
            className="appearance-none border rounded-lg w-full md:w-3/4 py-3 px-3 mb-4 leading-tight focus:outline-none focus:shadow-outline"
            id="mobile-number"
            type="text"
            placeholder="Your Mobile Number"
          />
          <input
            className="appearance-none border rounded-lg w-full md:w-3/4 py-3 px-3 mb-4 leading-tight focus:outline-none focus:shadow-outline"
            id="email-id"
            type="email"
            placeholder="Your Email"
          />
          <button className="transition ease-in-out delay-150 flex items-center bg-femmePink px-4 py-2 bg-primary-lightest text-white rounded-md hover:-translate-y-1 hover:scale-110 hover:duration-300">
            <IoIosSend className="mr-2" fill="#ffffff" />
            Submit
          </button>
        </motion.div>
      </div>

      {/* Responsive Map Section */}
      <motion.div
        className="flex-col justify-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-center py-10 text-gray-600 font-extrabold">
          We are Located Here:
        </h1>
        <iframe
          title="Office Location"
          className="w-full h-96 md:h-[500px] rounded-lg"
          src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
            address
          )}&key=${googleMapsApiKey}`}
          allowFullScreen
        ></iframe>
      </motion.div>
    </div>
  );
};

export default ContactForm;
