import React from "react";
import { motion } from "framer-motion";
import imageUrl from "../img/dnkadimeng.jpg";
import imageUrl2 from "../img/doctor.jpg"; // Import the second doctor's image

const specialists = [
  {
    name: "Dr. R Nkadimeng",
    title: "Female Health & Family Planning Specialist",
    description:
      "Dr. R Nkadimeng is a dedicated professional specializing in female health and family planning. With years of experience and a compassionate approach, she provides personalized care to women of all ages, focusing on their unique health needs and well-being.",
    imageUrl: imageUrl,
  },
  {
    name: "Dr. Jane Smith",
    title: "General Practitioner",
    description:
      "Dr. Jane Smith is a skilled general practitioner with a focus on comprehensive patient care. She brings a holistic approach to diagnosing and treating various health conditions, ensuring a thorough and empathetic healthcare experience.",
    imageUrl: imageUrl2, // Path to the second doctor's image
  },
];

const containerVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1, ease: "easeOut" },
  },
};

const MeetOurSpecialists: React.FC = () => {
  return (
    <motion.section
      className="bg-gray-100 py-12 px-4 flex flex-col items-center"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={containerVariants}
    >
      <h2 className="text-m uppercase font-bold text-center mb-8">
        Meet Our Doctors
        <span className="block mt-2 h-1 w-10 bg-primary-lighter mx-auto"></span>
      </h2>
      <div className="flex flex-col   md:pl-24 mdt:flex-row gap-10">
        {specialists.map((specialist, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center mdt:flex-row mdt:items-center w-full mdt:w-full"
            variants={containerVariants}
          >
            <motion.img
              src={specialist.imageUrl}
              alt={specialist.name}
              className="w-80 h-80 rounded-full object-cover mb-6 mdt:mb-0 mdt:mr-6"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.7, ease: "easeOut" }}
            />
            <motion.div
              className="text-center mdt:text-left  mdt:w-1/2"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.7, delay: 0.3, ease: "easeOut" }}
            >
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                {specialist.name}
              </h3>
              <p className="text-m text-primary-lightest mb-4 mdt:text-l">
                {specialist.title}
              </p>
              <p className="text-m text-gray-600">{specialist.description}</p>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default MeetOurSpecialists;
