// src/components/Increment2.tsx
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { increment2, decrement2 } from "../counterSlice";

const Increment2: React.FC = () => {
  const count2 = useSelector((state: RootState) => state.counter.count2);
  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-center rounded-md bg-white p-2">
      <button
        onClick={() => dispatch(decrement2())}
        className="text-xl px-3 py-1 border rounded-l-md bg-gray-200 hover:bg-gray-300"
      >
        -
      </button>
      <span className="text-xl mx-4">{count2}</span>
      <button
        onClick={() => dispatch(increment2())}
        className="text-xl px-3 py-1 border rounded-r-md bg-gray-200 hover:bg-gray-300"
      >
        +
      </button>
    </div>
  );
};

export default Increment2;
