import axios from "axios";
import React, { useEffect, useState } from "react";
import { LifeLine } from "react-loading-indicators";

type Interval = {
  time: string;
  available: boolean;
};

type IntervalGridProps = {
  selectedTimeSlot: string;
  date: string;
  handleSelectTimeSlot: (time: string) => void;
};

// Generate 15 intervals of 30 minutes between 18:30 and 21:00
const generateIntervals = (takenTimes: string[]): Interval[] => {
  const startTime = new Date();
  startTime.setHours(18, 0, 0, 0); // Start at 18:00

  const intervals: Interval[] = [];
  for (let i = 0; i < 12; i++) {
    // Generate 12 intervals
    const time = new Date(startTime.getTime() + i * 15 * 60000); // Add 15 minutes intervals
    const formattedTime = time.toTimeString().slice(0, 5); // Format as HH:MM

    intervals.push({
      time: formattedTime,
      available: !takenTimes.includes(formattedTime),
    });
  }
  return intervals;
};

const IntervalGrid: React.FC<IntervalGridProps> = ({
  selectedTimeSlot,
  handleSelectTimeSlot,
  date,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  //get all the slots for the selected date
  useEffect(() => {
    axios
      .get(`${baseUrl}/getslots`, {
        headers: {
          selectedDate: date,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setSelectedSlots(response.data.slots);
          setLoading(false);
        }
      })
      .catch((error) => {});
  }, [date, baseUrl]);
  const intervals = generateIntervals(selectedSlots);


  if (loading) {
    return (
      <div className="flex items-center mt-36 ">
        <LifeLine
          color="#ff5d8f"
          size="medium"
          text="Loading Slots"
          textColor=""
        />
      </div>
    );
  }
  return (
    <div>
      <h1 className="text-center font-semibold mb-4 text-primary-lightest">
        Choose slot
        <div className="flex items-center justify-center pt-2">
          <div className="flex items-center mr-4">
            <span className="block w-4 h-4 bg-primary-light rounded-full mr-1"></span>
            <span className="font-normal text-gray-500 text-xs">Available</span>
          </div>
          <div className="flex items-center mr-4">
            <span className="block w-4 h-4 bg-gray-700 rounded-full mr-1"></span>
            <span className="font-normal text-gray-500 text-xs">
              Not Available
            </span>
          </div>
          <div className="flex items-center">
            <span className="block w-4 h-4 bg-primary-lightd rounded-full mr-1"></span>
            <span className="font-normal text-gray-500 text-xs">
              Selected Slot
            </span>
          </div>
        </div>
      </h1>
      <div className="grid grid-cols-4 grid-rows-3 gap-4 p-4">
        {intervals.map((interval, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center p-4 rounded-lg ${
              interval.available
                ? selectedTimeSlot === interval.time
                  ? "bg-primary-lightd text-white"
                  : "bg-primary-light hover:bg-primary-lightd hover:text-white cursor-pointer"
                : "bg-gray-700 text-gray-400"
            }`}
            onClick={() =>
              interval.available && handleSelectTimeSlot(interval.time)
            }
          >
            <span className="text-xs md:text-lg font-semibold">
              {interval.time}
            </span>
            {/* {interval.available && <FaCheck className="text-green-600 mt-2" />} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntervalGrid;
