import React, { ReactNode } from "react";
import NavigationBar from "./NavigationBar"; // Adjust the path if necessary

import { FaInstagram } from "react-icons/fa"; // Import Instagram icon

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <NavigationBar />
      <main className="flex-grow">{children}</main>
      <footer className="bg-primary-lightest p-4 text-white text-center">
        <p className="mt-2">© 2024 Femme: Female Health & Family Planning</p>
        <div className="flex flex-col items-center justify-center space-y-2 sm:space-y-0 sm:flex-row sm:space-x-4">
          <a
            href="https://www.instagram.com/femme_familyplanning/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-primary flex items-center"
          >
            Visit us on:
            <FaInstagram className="ml-1" />
          </a>
          <a
            href="mailto:Femmefamilyplanning@gmail.com"
            className="text-white hover:underline"
          >
            Femmefamilyplanning@gmail.com
          </a>
          <a href="tel:+27714343982" className="text-white hover:underline">
            +27 71 434 3982
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
