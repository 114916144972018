import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaCartShopping, FaBars } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import logo from "../img/LG22.png";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { motion } from "framer-motion";
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { auth } from "../firebase";
import { FaSignOutAlt } from "react-icons/fa";

const NavigationBar: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const currentPageStyle = {
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "2px solid #FFC0CB",
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const location = useLocation();
  const pathname = location.pathname;

  const getInitials = (displayName: string | null) => {
    if (!displayName) return "";
    return displayName
      .split(" ")
      .map((part) => part.charAt(0).toUpperCase())
      .join("");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-primary-lightest shadow-md py-4 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <img src={logo} alt="Femme Logo" width={250} />
        </Link>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-8 items-center text-white">
          <li>
            <Link
              to="/"
              className="font-medium"
              style={pathname === "/" ? currentPageStyle : undefined}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/booking"
              className="font-medium"
              style={pathname === "/booking" ? currentPageStyle : undefined}
            >
              Booking
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="font-medium"
              style={pathname === "/about" ? currentPageStyle : undefined}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="font-medium"
              style={pathname === "/contact" ? currentPageStyle : undefined}
            >
              Contact
            </Link>
          </li>
        </ul>

        {/* Mobile Hamburger Menu Button */}
        <div onClick={toggleMobileMenu} className="flex mr-3 lg:hidden">
          <Link
            to="/cart"
            className="relative flex items-center mr-5 cursor-pointer"
          >
            <FaCartShopping size={25} fill="white" />
            {cartItems.length > 0 && (
              <motion.span
                className="absolute ml-4 mb-5 font-bold bg-primary-whitish text-primary-lightd rounded-full text-xs px-1"
                animate={{ rotate: [0, -10, 10, -10, 10, 0] }}
                transition={{ duration: 0.4, repeat: 1, repeatType: "loop" }}
              >
                {cartItems.length}
              </motion.span>
            )}
          </Link>
          {isMobileMenuOpen ? (
            <FaTimes size={24} fill="white" />
          ) : (
            <FaBars size={24} fill="white" />
          )}
        </div>

        {/* Mobile Menu */}
        <ul
          className={`lg:hidden fixed top-0 left-0 w-[60%] h-full bg-primary-lightest text-white ease-in-out duration-500 ${
            isMobileMenuOpen ? "left-0" : "left-[-100%]"
          }`}
        >
          <Link to="/">
            <img
              src={logo}
              alt="Femme Logo"
              className="mt-4 mb-4"
              width={250}
            />
          </Link>

          <li className="pl-10 mt-8">
            {user ? (
              <div className="items-center">
                <div className="flex items-center justify-center w-10 h-10 bg-primary-whitish rounded-full cursor-pointer">
                  {user.photoURL ? (
                    <img
                      src={user.photoURL}
                      alt="User Avatar"
                      className="w-full h-full object-cover rounded-full"
                    />
                  ) : (
                    <span className="text-primary-lighter uppercase font-bold">
                      {getInitials(
                        user.displayName ? user.displayName : user.email
                      )}
                    </span>
                  )}
                </div>
                <div>
                  <div className="">
                    <div className=" py-3 ">
                      <p className="font-semibold text-white">
                        {user.displayName}
                      </p>
                      <p className="text-xs text-gray-50">{user.email}</p>
                    </div>
                  </div>
                </div>
                {showMenu && (
                  <div className="absolute right-0 mt-44 w-fit bg-primary-whitish border border-gray-200 rounded-lg shadow-lg">
                    <div className="px-4 py-3 border-b border-gray-200">
                      <p className="font-semibold text-primary-lighter">
                        {user.displayName}
                      </p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>

                    <div className="px-4 py-2 border-t border-gray-200">
                      <button
                        className="w-full text-left text-gray-500 hover:bg-gray-100 rounded-md px-2 py-1 flex items-center"
                        onClick={handleLogout}
                      >
                        <FaSignOutAlt className="mr-2" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex space-x-2">
                <Link to="/login">
                  <button className="px-2 py-2 text-white font-normal rounded-lg hover:bg-primary-lightd transition duration-300">
                    Login
                  </button>
                </Link>
                <Link to="/sign-up">
                  <button className="px-2 py-2 text-white font-normal rounded-lg hover:bg-primary-lightd transition duration-300 border border-white">
                    Sign Up
                  </button>
                </Link>
              </div>
            )}
          </li>
          <li
            className="pl-10 mt-3 p-4 border-b uppercase border-primary-whitish"
            onClick={toggleMobileMenu}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            className="pl-10 p-4 border-b uppercase border-primary-whitish"
            onClick={toggleMobileMenu}
          >
            <Link to="/booking">Booking</Link>
          </li>
          <li
            className="pl-10 p-4 border-b uppercase border-primary-whitish"
            onClick={toggleMobileMenu}
          >
            <Link to="/about">About</Link>
          </li>
          <li
            className="pl-10 p-4 border-b uppercase border-primary-whitish"
            onClick={toggleMobileMenu}
          >
            <Link to="/contact">Contact</Link>
          </li>

          {user && (
            <li>
              <div className="px-4 py-10 ">
                <button
                  className="w-full text-left text-white hover:bg-gray-100 rounded-md px-2 py-1 flex items-center"
                  onClick={handleLogout}
                >
                  <FaSignOutAlt className="mr-2" />
                  Logout
                </button>
              </div>
            </li>
          )}
        </ul>

        {/* Cart and Authentication Buttons */}
        <div className="hidden lg:flex items-center space-x-6">
          <Link
            to="/cart"
            className="relative flex items-center mr-5 cursor-pointer"
          >
            <FaCartShopping size={25} fill="white" />
            {cartItems.length > 0 && (
              <motion.span
                className="absolute ml-4 mb-5 font-bold bg-primary-whitish text-primary-lightd rounded-full text-xs px-1"
                animate={{ rotate: [0, -10, 10, -10, 10, 0] }}
                transition={{ duration: 0.4, repeat: 1, repeatType: "loop" }}
              >
                {cartItems.length}
              </motion.span>
            )}
          </Link>

          {user ? (
            <div className="relative flex items-center">
              <div
                className="flex items-center justify-center w-10 h-10 bg-primary-whitish rounded-full cursor-pointer"
                onClick={() => setShowMenu(true)}
              >
                {user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt="User Avatar"
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <span className="text-primary-lighter uppercase font-bold">
                    {getInitials(
                      user.displayName ? user.displayName : user.email
                    )}
                  </span>
                )}
              </div>
              {showMenu && (
                <div
                  onMouseLeave={() => setShowMenu(false)}
                  className="absolute right-0 mt-44 w-fit bg-primary-whitish border border-gray-200 rounded-lg shadow-lg"
                >
                  <div className="px-4 py-3 border-b border-gray-200">
                    <p className="font-semibold text-primary-lighter">
                      {user.displayName}
                    </p>
                    <p className="text-sm text-gray-500">{user.email}</p>
                  </div>

                  <div className="px-4 py-2 border-t border-gray-200">
                    <button
                      className="w-full text-left text-gray-500 hover:bg-gray-100 rounded-md px-2 py-1 flex items-center"
                      onClick={handleLogout}
                    >
                      <FaSignOutAlt className="mr-2" />
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex space-x-2">
              <Link to="/login">
                <button className="px-4 py-2 text-white font-normal rounded-lg hover:bg-primary-lightd transition duration-300">
                  Login
                </button>
              </Link>
              <Link to="/sign-up">
                <button className="px-4 py-2 text-white font-normal rounded-lg hover:bg-primary-lightd transition duration-300 border border-white">
                  Sign Up
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
