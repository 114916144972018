import React from "react";

interface MissingInfoProps {
  isOpen: boolean;
  onClose: () => void;
  missingFields: { [key: string]: string };
}

const MissingInfoModal: React.FC<MissingInfoProps> = ({
  isOpen,
  onClose,
  missingFields,
}) => {
  if (!isOpen) return null;

  return (
    <div className=" px-3 md:px-0  fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-black">Missing Info</h2>
        <p className="mb-4 text-black">
          The following fields are missing, please fill them in:
        </p>
        <ol className="list-decimal list-inside mb-4 text-black">
          {Object.entries(missingFields).map(([field, message]) => (
            <li key={field}>{message}</li>
          ))}
        </ol>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-primary-lighter text-white rounded hover:bg-primary-lightd"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default MissingInfoModal;
