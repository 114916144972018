import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Layout from "../components/Layout";
import HowWeWork from "../components/HowWeWork";
import { Calendar } from "primereact/calendar";
import { BsCalendarDateFill } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import IntervalGrid from "../components/IntervalGrid";
import { Za } from "react-flags-select";
import "react-phone-input-2/lib/style.css";
import { FaPlus } from "react-icons/fa6";
import { FaTimesCircle } from "react-icons/fa";
import { IoIosSave, IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdLocalHospital } from "react-icons/md";

import {
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaClock,
  FaCarAlt,
  FaCheck,
} from "react-icons/fa";

import { FaUserDoctor } from "react-icons/fa6";
import { RiServiceFill } from "react-icons/ri";
import { Nullable } from "primereact/ts-helpers";
import toast, { Toaster } from "react-hot-toast";
import ServicesList from "../components/ServiceList";
import MissingInfoModal from "../components/MissingInfoModal";
import CartButton from "../components/CartButton";
import AppointmentDetails from "../types/appointmentDetails";

import { useDispatch } from "react-redux";
import { addItemToCart } from "../cartSlice";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";

import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SaveMedicalHistory from "../components/SaveMedicalHistoryModal";
import MyBookings from "../components/MyBookings";


//check if user has signed in

interface Service {
  name: string;
  code: string;
  price: number;
}

type Doctor = {
  name: string;
  code: string;
};

interface CartItem {
  item: string;
  price: number;
  quantity: number;
}

const BookingForm: React.FC = () => {
  //set up user auth
  const [fullName, setFullName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [medicalHistory, setMedicalHistory] = useState<string>("");
  const [clientAddress, setClientAddress] = useState<string>("");
  const [allergies, setAllergies] = useState<string[]>([]);
  const [allergiesHis, setAllergiesHis] = useState<string[]>([]);
  const [transportOption, setTransportOption] = useState<string>("self");
  //check if details have been captured
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [hasMediHistory, setHasMediHistory] = useState<boolean>(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  //modal for medical history
  const [isMediHistoryModalOpen, setIsMediHistoryModalOpen] =
    useState<boolean>(false);

  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setFullName(currentUser?.displayName || "");
      setEmailAddress(currentUser?.email || "");

      axios
        .get(`${baseUrl}/getmedicalhistory`, {
          headers: { userUID: currentUser?.uid },
        })
        .then((res) => {
          if (res.data.success) {
            const { displayName, phoneNumber, allergies, medicalHistory } =
              res.data.data[0];
           
            setPhoneNumber(phoneNumber);
            setAllergiesHis(allergies);
            setFullName(
              !currentUser?.displayName ? displayName : currentUser?.displayName
            );
            setMedicalHistory(medicalHistory);
            setHasMediHistory(true);
          }
        })
        .catch((error) => {
          console.error("Error creating booking:", error);
          if (error.response) {
          } else if (error.request) {
            
          } else {
            
          }
        });
    });
    return () => unsubscribe();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [date, setDate] = useState<Nullable<Date>>(new Date());
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>({
    name: "",
    code: "",
  });

  const formatDate = (date: Date | Nullable): string => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const selectedDateStr: string = formatDate(date);
 
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>("");
  //form fields

  //handle adding and deleting of allergies
  const handleAllergyChange = (index: number, value: string) => {
    const newAllergies = [...allergies];
    newAllergies[index] = value;
    setAllergies(newAllergies);
  };
  const addAllergy = () => {
    setAllergies([...allergies, ""]);
  };
  const deleteAllergy = (index: number) => {
    setAllergies(allergies.filter((_, i) => i !== index));
  };

  //generate booking number FEMME542820240827153701
  const generateBookingNumber = (): string => {
    const prefix = "FEMME";
    const randomDigits = Math.floor(1000 + Math.random() * 9000).toString();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const dateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;
    const bookingNumber = `${prefix}${randomDigits}${dateTime}`;

    return bookingNumber;
  };

  const handleServiceChange = (e: MultiSelectChangeEvent) => {
    if (e.value.length <= 3) {
      setSelectedServices(e.value);
    } else {
      toast("You can only select 3 services", {
        icon: "⚠️",
        style: {
          padding: "16px",
        },
        position: "bottom-left",
      });
    }
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeModalMedi = () => {
    setIsMediHistoryModalOpen(false);
  };

  //close review modal

  const [missingFields, setMissingFields] = useState<{ [key: string]: string }>(
    {}
  );

  const [appointmentDetails, setAppointmentDetails] =
    useState<AppointmentDetails>({
      date: "",
      bookingNumber: "",
      selectedServices: null,
      selectedServicesPrices: [],
      selectedDoctor: "",
      selectedTimeSlot: "",
      fullName: "",
      phoneNumber: "",
      emailAddress: "",
      medicalHistory: "",
      allergies: [],
      userUID: "",
      clientAddress: "",
      transportOption: "self",
      servicePrice: 0,
      transportationFee: 0,
      totalToPay: 0,
    });

  const services: Service[] = [
    { name: "Injection", code: "IJ", price: 400 },
    { name: "Oral", code: "OR", price: 400 },
    { name: "Morning after pill", code: "MAP", price: 250 },
    { name: "Vitamin drip", code: "VD", price: 1500 },
    { name: "Consultation", code: "CONS", price: 460 },
  ];

  const doctors: Doctor[] = [
    { name: "Dr R Nkadimeng", code: "Dr R" },
 
  ];

  const handleTransportChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setTransportOption(event.target.value);
  };

  const handleSelectTimeSlot = (time: string) => {
    setSelectedTimeSlot(time);
  };

  const handleEditMyBookings = () => {
    setIsConfirmed(false);
  };

  //handle confirm booking
  const handleConfirmDetails = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsProcessing(true);
    setIsConfirmed(false);

    axios
      .get(`${baseUrl}/getmedicalhistory`, {
        headers: { userUID: user?.uid },
      })
      .then((res) => {
        if (!res.data.success && res.data.message === "notfound") {
          setIsMediHistoryModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error creating booking:", error);
        if (error.response) {
        } else if (error.request) {
       
        } else {
       
        }
      });

    // Initialize an object to track missing fields
    const missingFields: { [key: string]: string } = {};

    // Validate required fields and add missing ones to the object
    if (!date) missingFields.date = "Date is required";
    if (!selectedServices)
      missingFields.selectedServices = "Service is required";
    if (!selectedDoctor) missingFields.selectedDoctor = "Doctor is required";
    if (!selectedTimeSlot)
      missingFields.selectedTimeSlot = "Time slot is required";
    if (!fullName) missingFields.fullName = "Full name is required";
    if (!emailAddress) missingFields.emailAddress = "Email address is required";
    if (!phoneNumber) missingFields.phoneNumber = "Phone number is required";
    if (!clientAddress && transportOption === "Require (R100)")
      missingFields.clientAddress = "Client address is required";

    // If there are missing fields, alert the user and stop processing
    if (Object.keys(missingFields).length > 0) {
      setIsProcessing(false);
      setMissingFields(missingFields);
      setIsOpen(true);
      return;
    }
    // Get the transport fee based on the option chosen
    const transportFee: number = transportOption === "Require (R100)" ? 100 : 0;
    const selectedServiceNames = selectedServices
      .map((service) => service.name)
      .join(", ");
    const totalServicePrice = selectedServices.reduce(
      (total, service) => total + service.price,
      0
    );
    //get the prices
    const prices = selectedServices.map((service) => service.price);

    const newAppointmentDetails: AppointmentDetails = {
      date: selectedDateStr,
      bookingNumber: generateBookingNumber(),
      selectedServices: selectedServiceNames,
      selectedDoctor: selectedDoctor.name,
      selectedServicesPrices: prices,
      selectedTimeSlot: selectedTimeSlot,
      fullName: fullName,
      emailAddress: emailAddress,
      phoneNumber: phoneNumber,
      medicalHistory: medicalHistory,
      allergies: allergiesHis.length > 0 ? allergiesHis: allergies,
      userUID: user?.uid,
      clientAddress: clientAddress,
      transportOption: transportOption,
      servicePrice: totalServicePrice,
      transportationFee: transportFee,
      totalToPay: totalServicePrice + transportFee,
    };

    await new Promise((resolve) => setTimeout(resolve, 2000));

    if (!user) {
      alert("please login");
      setIsProcessing(false);
      navigate("/login");
    } else {
      setAppointmentDetails(newAppointmentDetails);
   
      setIsProcessing(false);
      setIsConfirmed(true);
      toast.success("Details Confirmed Successfully!");
 
    }
  };

  const addToCart = () => {
    const cartItems: CartItem[] = selectedServices.map((service) => ({
      item: service.name,
      price: service.price,
      quantity: 1, // Initialize with quantity 1
    }));
    toast.success("Items added to cart!", { position: "bottom-right" });
    dispatch(
      addItemToCart({ items: cartItems, bookingInfo: appointmentDetails })
    );
  };

  const formatPhoneNumber = (value: string): string => {
    const cleaned = value.replace(/\D/g, "");
    const limited = cleaned.substring(0, 10);
    const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      const [, g1, g2, g3] = match;
      return `${g1}${g2 ? " " + g2 : ""}${g3 ? " " + g3 : ""}`;
    }
    return limited;
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(formatPhoneNumber(event.target.value));
  };

  // Get the current date  Calculate the date 30 days from today
  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 30);

  //save user medical history
  const saveMedicalHistory = () => {
    axios
      .post(`${baseUrl}/savemedicalinfo`, {
        userUID: user?.uid,
        fullName: fullName,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        allergies: allergies,
        medicalHistory: medicalHistory,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("Medical history saved!", { position: "top-center" });
          setIsMediHistoryModalOpen(false);
        } else {
          toast.error("Error saving medical history", {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.error("Error saving medical history:", error);
        if (error.response) {
        } else if (error.request) {
    
        } else {
         
        }
      });
  };
  const [newBooking, setNewBooking] = useState<boolean>(false);
  const [hideNewBookingBtn, setHideNewBookingBtn] = useState<boolean>(true);
  const handleNewBooking = () => {
    setNewBooking(true);
    setHideNewBookingBtn(false);
  };

  const handleCloseNewBookingForm = () => {
    setNewBooking(false);
    setHideNewBookingBtn(true);
  };

  return (
    <Layout>
      <section className="">
        <MissingInfoModal
          isOpen={isOpen}
          onClose={closeModal}
          missingFields={missingFields}
        />

        <SaveMedicalHistory
          isOpen={isMediHistoryModalOpen}
          onClose={closeModalMedi}
          saveMedicalHistory={saveMedicalHistory}
        />

        <Toaster position="top-center" reverseOrder={false} />
        <ServicesList />

        {hideNewBookingBtn && (
          <div className="flex items-center justify-center pt-5">
            <button
              onClick={handleNewBooking}
              className="px-4 flex items-center justify-center bg-primary-lightest text-white py-2 rounded-lg hover:bg-primary-lightd"
            >
              <MdLocalHospital fill="white" size={25} className="mr-3" />
              New Booking
            </button>
          </div>
        )}

        {newBooking && (
          <div className="mr-5 mt-2 flex justify-end">
            <IoIosCloseCircle
              fill="red"
              size={25}
              onClick={handleCloseNewBookingForm}
            />
          </div>
        )}
        {newBooking && (
          <div className=" ml-5 mr-5 border rounded-sm flex pt-5 flex-col md:flex-wrap md:flex-row md:space-x-10 space-y-6 px-2 py-4 md:pl-10">
            <div className="flex flex-col md:mt-20 ">
              <label
                htmlFor="buttondisplay"
                className="font-bold block  text-primary-lightest"
              >
                Select Date
              </label>
              <Calendar
                id="buttondisplay"
                value={date}
                showIcon
                icon={() => <BsCalendarDateFill fill={"#ff4d6d"} />}
                onChange={(e) => setDate(e.value)}
                className="border rounded-md py-2 px-2.5"
                placeholder="Select Date"
                minDate={today}
                disabledDays={[0]}
              />

              <div className="card flex flex-col space-y-4 py-4">
                <label
                  htmlFor="buttondisplay"
                  className="font-bold block  text-primary-lightest"
                >
                  Select Service Required
                </label>
                <MultiSelect
                  value={selectedServices}
                  onChange={handleServiceChange}
                  options={services}
                  display="chip"
                  optionLabel="name"
                  placeholder="Select Services"
                  maxSelectedLabels={3}
                  className="w-full md:w-14rem border rounded-md text-xs"
                />
              </div>

              <div className="card flex flex-col space-y-4">
                <label
                  htmlFor="buttondisplay"
                  className="font-bold block  text-primary-lightest"
                >
                  Select your Doctor
                </label>
                <Dropdown
                  value={selectedDoctor}
                  onChange={(e) => setSelectedDoctor(e.value)}
                  options={doctors}
                  optionLabel="name"
                  placeholder="Choose your doctor"
                  className="w-full md:w-14rem border rounded-md"
                />
              </div>
            </div>
            <div className=" ">
              <IntervalGrid
                selectedTimeSlot={selectedTimeSlot}
                handleSelectTimeSlot={handleSelectTimeSlot}
                date={selectedDateStr}
              />
              {selectedTimeSlot && (
                <p className="text-center mt-4">
                  Selected Time Slot: <strong>{selectedTimeSlot}</strong>
                </p>
              )}
            </div>

            <div className=" w-full md:w-96 ">
              <h1 className="font-semibold text-primary-lightest">
                Please Fill in your Information..
              </h1>
              <form className="space-y-7 " onSubmit={handleConfirmDetails}>
                <div className="mt-14">
                  <FloatLabel>
                    <InputText
                      id="fullname"
                      required
                      type="text"
                      value={fullName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFullName(e.target.value)
                      }
                      className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                    <label htmlFor="fullname">Full Name</label>
                  </FloatLabel>
                </div>
                <div className="mt-2">
                  <FloatLabel>
                    <InputText
                      id="email"
                      type="email"
                      required
                      value={emailAddress}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmailAddress(e.target.value)
                      }
                      className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                    <label htmlFor="email">Email Address</label>
                  </FloatLabel>
                </div>

                <div className="flex  items-center border border-gray-300 rounded-md shadow-sm">
                  <div className=" w-[85px] flex items-center  bg-gray-100">
                    <input
                      type="text"
                      value="+27"
                      disabled
                      className="pl-2 py-3 w-1/2 border-none rounded-l-md  text-gray-600  "
                      readOnly
                    />
                    <Za className="ml-2"/>
                  </div>
                  <FloatLabel>
                    <InputText
                      id="text"
                      type="text"
                      required
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      className="mt-1 block w-full px-3 py-3  rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    />
                    <label htmlFor="allergies">Phone Number</label>
                  </FloatLabel>
                </div>
                <div className="mt-2">
                  <label className="block text-gray-700 font-semibold">
                    Allergies
                  </label>
                  {allergiesHis.length > 0 && (
                    <p className="flex items-center text-xs">
                      {" "}
                      <IoIosSave fill="#ff5d8f" className="mr-2" /> [
                      {allergiesHis.join(", ")}]
                    </p>
                  )}
                  {allergies.map((allergy, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <input
                        className="appearance-none border rounded-lg w-full py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Allergy"
                        value={allergy}
                        onChange={(e) =>
                          handleAllergyChange(index, e.target.value)
                        }
                      />
                      <button
                        type="button"
                        className="ml-2 p-2 text-red-500 hover:text-red-700"
                        onClick={() => deleteAllergy(index)}
                      >
                        <FaTimesCircle />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="text-primary-lighter text-sm bg-primary-light p-1 rounded-l  flex justify-center items-center hover:text-primary-lighter"
                    onClick={addAllergy}
                  >
                    <FaPlus className="mr-2" />{" "}
                    {allergies.length > 0
                      ? "Add Another allergy"
                      : "Add allergy"}
                  </button>
                </div>
                <div>
                  <textarea
                    className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-femmePink focus:border-femmePink"
                    rows={4}
                    placeholder="Medical History"
                    value={medicalHistory}
                    onChange={(e) => setMedicalHistory(e.target.value)}
                  />
                </div>
                <div>
                  <select
                    className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-femmePink focus:border-femmePink"
                    value={transportOption}
                    onChange={handleTransportChange}
                  >
                    <option value="self">Self Transportation</option>
                    <option value="Require (R100)">Require Transport</option>
                  </select>
                </div>
                {transportOption === "Require (R100)" && (
                  <div className="mt-4">
                    <input
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:ring-femmePink focus:border-femmePink"
                      placeholder="Address"
                      value={clientAddress}
                      onChange={(e) => setClientAddress(e.target.value)}
                    />
                    <p className="text-xs">
                      <span className="text-red-600">*</span> please note you
                      will be charged extra <strong>R100</strong> for
                      transportation
                    </p>
                  </div>
                )}

                <button
                  type="submit"
                  className={`w-full flex items-center justify-center py-2 rounded-lg ${
                    isConfirmed
                      ? "bg-gray-400 text-white cursor-not-allowed"
                      : "bg-primary-lightest text-white hover:bg-primary-lightd"
                  }`}
                  disabled={isProcessing || isConfirmed}
                >
                  {isProcessing ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline mr-3 w-4 h-4 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        ></path>
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      Processing...
                    </>
                  ) : isConfirmed ? (
                    <>
                      <FaCheck className="text-green-500 mr-2" />
                      Details Confirmed
                    </>
                  ) : (
                    "Confirm Booking Details"
                  )}
                </button>
                {isConfirmed && (
                  <button
                    onClick={handleEditMyBookings}
                    className="w-full flex items-center justify-center bg-primary-lightest text-white py-2 rounded-lg hover:bg-primary-lightd"
                  >
                    Edit My Booking
                  </button>
                )}
              </form>
            </div>
            <div className=" md:pl-10 ">
              <h1 className="font-semibold text-primary-lightest">
                Booking Summary
              </h1>
              {isConfirmed && (
                <div className="max-w-l mx-auto bg-white mt-10 rounded-lg overflow-hidden">
                  <div className=" py-4">
                    <div className="flex items-center mb-2">
                      <RiServiceFill className="text-gray-600 mr-2" />
                      <span>
                        Service(s) Booked:{" "}
                        <span className="font-bold">
                          {appointmentDetails.selectedServices}
                        </span>
                      </span>
                    </div>
                    <div className="flex items-center mb-2">
                      <FaUserDoctor className="text-gray-600 mr-2" />
                      <span>{appointmentDetails.selectedDoctor}</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <FaMapMarkerAlt className="text-gray-600 mr-2" />
                      <span>868 Kyoto Street Kyalami Estates, Midrand</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <FaCalendarAlt className="text-gray-600 mr-2" />
                      <span>{appointmentDetails.date}</span>
                      <FaClock className="text-gray-600 ml-2" />
                      <span className="px-1">
                        starting {appointmentDetails.selectedTimeSlot}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <FaCarAlt className="text-gray-600 mr-2" />
                      <span>
                        Require Trasport:{" "}
                        <span className="font-bold">
                          {appointmentDetails.transportOption === "require"
                            ? "YES"
                            : "NO"}
                        </span>
                      </span>
                    </div>
                    <div className="mt-5">
                      <div className="flex justify-between mb-2">
                        <span className="font-semibold">Service Price</span>
                        <span>{`R${appointmentDetails.servicePrice}`}</span>
                      </div>
                      <div className="flex justify-between mb-2">
                        <span className="font-semibold">
                          Transportation Fee
                        </span>
                        <span>{`R${appointmentDetails.transportationFee}`}</span>
                      </div>
                      <div className="flex justify-between mb-4">
                        <span className="font-semibold">Total</span>
                        <span>{`R${appointmentDetails.totalToPay}`}</span>
                      </div>
                      <CartButton onClick={addToCart} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* <HowWeWork
          type="boo"
          heading="Booking your Appoinment is as Easy as.., Yes You guessed It 😉"
        /> */}
        <div className="py-3">
          {user ? (
            <MyBookings userUID={user?.uid} />
          ) : (
            <div className="flex-row py-10">
              <h1 className="py-2 text-primary-lightest font-semibold text-center">
                My Bookings
              </h1>
              <Link to={"/login"}>
                <p className="text-center">
                  <span className="text-primary-lightest font-semibold cursor-pointer">
                    Login
                  </span>{" "}
                  To see your Bookings
                </p>
              </Link>
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default BookingForm;
